import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import { CookiesProvider, useCookies } from 'react-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'

import Details from '../components/Details'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import ShorterContent from '../components/ShorterContent'
import VideoNews from '../components/VideoNews'
import Footer from '../components/Footer'
import Menu from '../components/Menu'

export default function PreIpo({ data, pageContext }) {
  const [cookies, setCookie] = useCookies(['name'])
  const [popupVisible, setPopupVisible] = useState(true)

  const {
    heroText,
    heroBackground,
    seoMetaTags,
    introText,
    videoNews,
    howToInvest,
    investmentHighlights,
    links,
    faq,
    outroImage,
    outroText,
    popup
  } = data.datoCmsPreIpo

  function acceptPopup() {
    setPopupVisible(false)
    setCookie('euro', 'ye')
  }

  return <Layout transparentMenu locale={pageContext.locale}>
    <Menu
          transparent={true}
          menu={data.datoCmsNewMenu}
          socialMedia={data.datoCmsFooter.socialMedia}
        />
    <CookiesProvider>
      <React.Fragment>
        <HelmetDatoCms seo={seoMetaTags}>
          <title>Nordic Asia - Investment Advisory Group</title>
        </HelmetDatoCms>
        <Hero heroNode={heroText} heroBackground={heroBackground} />
      
        <section className="container center-width prose margin-top">
          <ShorterContent>
            <div
              className="text-justified"
              dangerouslySetInnerHTML={{
                __html: introText,
              }}
            ></div>
          </ShorterContent>
        </section>

        <div className="gray-background">
          <section className="container center-width prose margin-top">
            <VideoNews news={videoNews} />
          </section>
        </div>

        <section className="container center-width prose margin-top">
          <ShorterContent>
            <div
              className="list-fancy"
              dangerouslySetInnerHTML={{
                __html: howToInvest,
              }}
            ></div>
            <div className="relative">
              <div id="delta-i-emissionen" className="anchor"></div>
              <div
                className="list-fancy"
                dangerouslySetInnerHTML={{
                  __html: investmentHighlights,
                }}
              ></div>
            </div>


            <div className="grid margin-top-small margin-bottom-big">

              {links.map((link, i) =>
                <div className="col col--md-8" key={link.linkTitle}>
                  <a className={`margin-bottom-negative text-left full-width btn ${i && 'btn--outline'}`} href={link.document ? link.document.url : link.linkTarget}>
                    <div className="flex flex--v-center">
                      {link.icon ?
                        <img src={link.icon.url} alt="" style={{ height: i ? '1em' : '2em' }} className="margin-right-small" /> :
                        <FontAwesomeIcon icon={faFilePdf} style={{ height: i ? '1em' : '2em' }} className="margin-right-small" />
                      }
                      {link.linkTitle}
                    </div></a>
                </div>
              )}

            </div>
          </ShorterContent>
        </section>

        <div className="gray-background">
          <div className="container center-width prose">
            <div className="grid">
              <div className="col col--lg-6">
                {faq.slice(0, Math.ceil(faq.length / 2)).map(faq => (
                  <Details title={faq.title} content={faq.content} key={faq.id} />
                ))}
              </div>
              <div className="col col--lg-6">
                {faq.slice(Math.ceil(faq.length / 2)).map(faq => (
                  <Details title={faq.title} content={faq.content} key={faq.id} />
                ))}
              </div>
            </div>
          </div>
        </div>


        <section className="container-big center-width prose">
          <div className="grid flex--v-center">
            <div className="col col--md-4">
              <Img fluid={outroImage.fluid} className="border-radius-full" />
            </div>
            <div className="col col--md-8">

              <div
                className="text-justified list-fancy"
                dangerouslySetInnerHTML={{
                  __html: outroText,
                }}
              ></div>
            </div>
          </div>
        </section>

      </React.Fragment>






      {typeof window !== 'undefined' && cookies.euro !== 'ye' && (<div className={`popup ${popupVisible ? 'popup--visible' : ''}`} id="popup">
        <div className="popup__body" style={{ maxWidth: '60em' }}>
          <div className="popup__content">
            <h1>{cookies.euro}</h1>
            <h1 className="margin-bottom-small">Disclaimer (viktig information)</h1>
            <div className="prose"
              dangerouslySetInnerHTML={{
                __html: popup,
              }}>
            </div>
            <div className="toolstrip flex--right toolstrip--no-margin">
              <a href="/" className="btn btn--outline">Bekräftar inte</a>
              <button className="btn" id="hide" onClick={acceptPopup}>Bekräftar</button>
            </div>
          </div>
        </div>
      </div>)}
    </CookiesProvider>
    <Footer footer={data.datoCmsFooter} />
  </Layout>
}


export const query = graphql`
query {
  datoCmsPreIpo {
    seoMetaTags {
      tags
    }
    heroText
    heroBackground {
      fluid(maxWidth: 2560, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
    }
    introText
    videoNews {
      id
      spotifyLink
      videoLink
      oembedCode
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
    }
    howToInvest
    investmentHighlights
    links {
      ... on DatoCmsLinkWithIcon {
        id
        linkTarget
        linkTitle
        icon {
          url
        }
      }
      ... on DatoCmsDocumentLink {
        id
        linkTitle
        document {
          url
        }
      }
    }
    faq {
      id
      title
      content
    }
    outroImage {
      fluid(maxWidth: 350, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
    }
    outroText
    popup
  }
  datoCmsNewMenu {
    ...Menu
  }
  datoCmsFooter {
    ...Footer
  }
}`
